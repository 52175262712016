import { Link } from "react-router-dom";
import Dropdown from "react-dropdown";
import { useEffect, useState } from "react";

import Header from "../components/header";
import Icon from "../components/icon";
import Loader from "../components/loader";

import apiCall from "../functions/apiCall";
import toast from "../functions/toast";

const Ground = () => {
    const [grounds, setGrounds] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const [country, setCountry] = useState(null);

    const countries = ["All", "India", "United States"];

    const getGrounds = async () => {
        setShowLoader(true);
        try {
            const path =
                country && country !== "All"
                    ? "/grounds/?country=" + country
                    : "/grounds";
            const res = await apiCall({ path });
            setGrounds(res);
        } catch (err) {
            console.log("🚀 ~ file: ground.js:17 ~ getGrounds ~ err:", err);
            toast.error(err);
        }
        setShowLoader(false);
    };

    useEffect(() => {
        getGrounds();
    }, [country]);

    return (
        <div className="categories tournaments">
            <Header title="Tournaments" />
            <Loader show={showLoader} />
            <div className="wrapper">
                <Dropdown
                    options={countries}
                    placeholder="Country"
                    className="dropdown teamdropdown"
                    onChange={({ value }) => setCountry(value)}
                />
                <Link to="/ground/add" className="btn add">
                    <Icon name="add" /> Add Ground
                </Link>
            </div>

            <div className="cards ">
                {grounds.map((item, index) => (
                    <Link
                        className="matchse card groundcard"
                        key={index}
                        to={"/ground/" + item._id}
                    >
                        <section>
                            <div>
                                <span>{item.name}</span>
                            </div>
                        </section>
                        <div className="groundcountry">
                            <span>Country : {item.country}</span>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default Ground;
