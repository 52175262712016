import { useEffect, useState } from "react";
import Dropdown from "react-dropdown";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";

import Loader from "../components/loader";
import Icon from "../components/icon";

import apiCall, { baseURL } from "../functions/apiCall";
import toast from "../functions/toast";

const Scoring = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [match, setMatch] = useState(null);
  const [teams, setTeams] = useState(null);
  const [tossWinner, setTossWinner] = useState(null);
  const [tossWinnerTeamChoice, setTossWinnerTeamChoice] = useState(null);
  const [players, setPlayers] = useState([]);
  const [playerA, setPlayerA] = useState(null);
  const [playerB, setPlayerB] = useState(null);
  const [bowler, setBowler] = useState(null);
  const [over, setOver] = useState(null);
  const [bettingTeam, setBettingTeam] = useState(null);
  const [bowlingTeam, setBowlingTeam] = useState(null);
  const [bowlTeamPlayers, setBowlTeamPlayers] = useState([]);
  const [whoPass, setWhoPass] = useState(null);
  const [whoThrow, setWhoThrow] = useState(null);
  const [wicketResult, setWicketResult] = useState(null);
  const [showRunOutModal, setShowRunOutModal] = useState(false);
  const [whoOut, setWhoOut] = useState(null);
  const [whoCaught, setWhoCaught] = useState(null);
  const [caughtOutModal, setCaughtModal] = useState(false);
  const [stumpedModal, setStumpedModal] = useState(false);
  const [wicketKeeper, setWicketKeeper] = useState(null);
  const [liveResults, setLiveResults] = useState([]);
  const [resId, setResId] = useState(null);
  const [liveScore, setLiveScore] = useState(null);
  const [lineupDone, setLineupDone] = useState(false);
  const [lineupTab, setLineupTab] = useState(0);
  const [teamAPlayers, setTeamAPlayers] = useState([]);
  const [teamBPlayers, setTeamBPlayers] = useState([]);
  const [teamAnouncedPlayers, setTeamAAnouncedPlayers] = useState([]);
  const [teamBnouncedPlayers, setTeamBAnouncedPlayers] = useState([]);
  const [teamASubsitutePlayers, setTeamASubsitutePlayers] = useState([]);
  const [teamBSubsitutePlayers, setTeamBSubsitutePlayers] = useState([]);

  const navigate = useNavigate();

  const { id } = useParams();

  const modalStyle = {
    content: {
      width: 300,
      height: 300,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: 12,
      background: "#000",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  const runs = [
    { title: "Dott Ball", run: 0 },
    { title: "1", run: 1 },
    { title: "2", run: 2 },
    { title: "3", run: 3 },
    { title: "4", run: 4 },
    { title: "5", run: 5 },
    { title: "6", run: 6 },
    { title: "7", run: 7 },
    { title: "Six", run: 6 },
    { title: "Four", run: 4 },
  ];

  const extra = [
    { title: "WD", run: 1 },
    { title: "WD + Four", run: 5 },
    { title: "WD + Runs", run: 1 }, // menual
    { title: "NB", run: 1 },
    { title: "NB + Four", run: 5 },
    { title: "NB + Six", run: 7 },
    { title: "NB + Runs", run: 1 }, // menual
    { title: "LB + Four", run: 4 },
    { title: "LB + Runs", run: 0 }, // menual
    { title: "BY + Four", run: 4 },
    { title: "BY + Runs", run: 0 }, // menual
  ];

  const wicket = [
    { title: "Bowled" },
    { title: "Caught Out" },
    { title: "LBW" },
    { title: "Stumped" },
    { title: "Run Out" },
    { title: "Run Out + Runs" },
    { title: "Hit Wicket" },
    { title: "Retired Out" },
  ];

  const getMatch = async () => {
    setShowLoader(true);
    setPlayers([]);
    setBowlTeamPlayers([]);
    setBowlTeamPlayers([]);
    setPlayers([]);

    try {
      const [res] = await apiCall({ path: "/match/" + id });
      setMatch(res);
      !res.bowler && setBowler(null);
      !res.playerA && setPlayerA(null);
      !res.playerB && setPlayerB(null);
      setTossWinner(res.tossWinner);
      setTossWinnerTeamChoice(res.tossWinnerTeamChoice);
      setOver(res._over || "0.0");
      setResId(null);
    } catch (err) {
      console.log("🚀 ~ file: scoring.js:45 ~ getMatch ~ err:", err);
    }
    setShowLoader(false);
  };

  const getTeams = async () => {
    setShowLoader(true);
    try {
      const [teamA] = await apiCall({ path: "/team/" + match.teamA });
      const [teamB] = await apiCall({ path: "/team/" + match.teamB });
      setLineupDone(
        teamA.anouncedPlayers != null && teamB.anouncedPlayers != null
      );
      setTeams({ teamA, teamB });
    } catch (err) {
      console.log("🚀 ~ file: scoring.js:63 ~ getTeams ~ err:", err);
    }
    setShowLoader(false);
  };

  const tossSubmit = async () => {
    setShowLoader(true);
    try {
      const data = {
        tossWinner,
        tossWinnerTeamChoice,
        _id: id,
        note: `${teams[tossWinner].shortName} opp to ${
          tossWinnerTeamChoice === "Betting" ? "Bat" : "Ball"
        } first`,
      };
      await apiCall({ method: "post", path: "/match/add", data });
      toast.success("Toss result declared successfuly");
      getMatch();
    } catch (err) {
      console.log("🚀 ~ file: scoring.js:77 ~ tossSubmit ~ err:", err);
      toast.error("Toss result does not declared");
    }
    setShowLoader(false);
  };

  const getPlayer = async (id) => {
    try {
      const player = await apiCall({ path: "/player/" + id });
      setPlayers((prev) => [...prev, ...player]);
    } catch (err) {
      console.log("🚀 ~ file: scoring.js:93 ~ getPlayer ~ err:", err);
    }
  };

  const getPlayers = async () => {
    setPlayers([]);
    try {
      const players = bettingTeam.anouncedPlayers
        ? [
            ...JSON.parse(bettingTeam.anouncedPlayers),
            ...JSON.parse(bettingTeam.subsitutePlayers),
          ]
        : JSON.parse(bettingTeam.players);
      players.forEach((player) => getPlayer(player));
    } catch (err) {
      console.log("🚀 ~ file: scoring.js:92 ~ getPlayers ~ err:", err);
    }
  };

  const getLiveScore = async () => {
    setShowLoader(true);
    try {
      const score = await apiCall({ path: "/live-score/" + id });
      setLiveScore(score);
    } catch (err) {
      console.log("🚀 ~ file: scoring.js:167 ~ getLiveScore ~ err:", err);
    }
    setShowLoader(false);
  };

  const changePlayer = async (key, val) => {
    setShowLoader(true);
    try {
      const data = {
        _id: id,
        newBowler: bowler && bowler != 0 ? true : false,
        over,
      };
      data[key] = val;
      await apiCall({ path: "/match/add", data, method: "post" });
      //   window.location.reload();
      getMatch();
    } catch (err) {
      console.log("🚀 ~ file: scoring.js:122 ~ changePlayer ~ err:", err);
      setShowLoader(false);
    }
  };

  const updateStriker = async (pid) => {
    setShowLoader(true);
    try {
      const data = { striker: pid, _id: id };
      await apiCall({ path: "/match/add", data, method: "post" });
      getMatch();
    } catch (err) {
      console.log("🚀 ~ file: scoring.js:133 ~ updateStriker ~ err:", err);
    }
    setShowLoader(false);
  };

  const addResult = async (result, run, whoOut) => {
    let _runs = run;
    let extraInfo = null;

    if (!whoOut) {
      if (playerA._id === match.striker) {
        whoOut = playerA._id;
      } else {
        whoOut = playerB._id;
      }
    }

    if (result === "Run Out" || result === "Run Out + Runs") {
      extraInfo = { whoPass, whoThrow };
    } else if (result === "Caught Out") {
      extraInfo = { whoCaught };
    } else if (result === "Stumped") {
      extraInfo = { wicketKeeper };
    }

    extraInfo = extraInfo ? JSON.stringify(extraInfo) : null;

    const isOverIncrease =
      result.split(" ")[0] !== "WD" && result.split(" ")[0] !== "NB";

    var strikerChange = true;

    if (
      run == 2 ||
      run == 0 ||
      run == 4 ||
      run == 6 ||
      result == "WD" ||
      result == "NB"
    )
      strikerChange = false;

    // if (`${over}`.split(".")[1] == 0) strikerChange = true;

    if (result.split("+ ")[1] === "Runs") {
      const runs = prompt("Enter Runs For " + result.split(" ")[0]);
      if (!runs) return false;
      if (!runs.match(/^[1-7]$/)) return toast.error("Enter valid runs");
      _runs = run + parseInt(runs);
      if (runs == 1 && runs == 3 && runs == 5) strikerChange = true;
    }

    setShowLoader(true);

    try {
      const data = {
        ...{
          result,
          run: _runs || 0,
          tournament: match.tournament,
          _match: id,
          team: bettingTeam._id,
          bowlingTeam: bowlingTeam._id,
          batsman: match.striker,
          bowler: bowler._id,
          _over:
            resId &&
            (result.split(" ")[0] === "WD" || result.split(" ")[0] === "NB")
              ? prevOver()
              : liveResults.find((i) => i._id == resId) &&
                (liveResults
                  .find((i) => i._id == resId)
                  .result.split(" ")[0] === "WD" ||
                  liveResults
                    .find((i) => i._id == resId)
                    .result.split(" ")[0] === "NB" ||
                  result === "Dott Ball")
              ? nextOver(!isOverIncrease)
              : resId
              ? over
              : nextOver(!isOverIncrease),
          _id: resId,
        },
        ...(extraInfo && { extraInfo }),
        ...(!run && run !== 0 && { whoOut }),
      };

      const [sp0] = result.split(" + ");

      if (!run && result !== "Dott Ball" && sp0 !== "LB" && sp0 !== "BY")
        data.wicket = 1;

      let outedPlayer = null;

      if (playerA._id === whoOut) {
        outedPlayer = { playerA: 0 };
      } else {
        outedPlayer = { playerB: 0 };
      }

      let newStriker = null;

      if (playerA._id === match.striker) {
        newStriker = playerB._id;
      } else {
        newStriker = playerA._id;
      }

      await apiCall({
        path: "/match/add",
        method: "post",
        data: {
          ...{
            _id: id,
            _over:
              resId &&
              (result.split(" ")[0] === "WD" || result.split(" ")[0] === "NB")
                ? prevOver()
                : resId
                ? over
                : nextOver(!isOverIncrease),
          },
          ...(!run && run !== 0 && outedPlayer),
          ...(nextOver(!isOverIncrease) % 1 === 0 &&
            over % 1 !== 0 && {
              bowler: 0,
            }),
          ...(strikerChange && {
            striker: newStriker,
          }),
        },
      });
      await apiCall({ path: "/result/add", data, method: "post" });

      setShowRunOutModal(false);
      setWhoOut(null);
      setWhoThrow(null);
      setWhoPass(null);
      setWhoCaught(null);
      setCaughtModal(false);
      setStumpedModal(false);
      setWicketKeeper(null);
      setResId(null);
      getMatch();
      toast.success("Result declared successfully");
    } catch (err) {
      console.log("🚀 ~ file: scoring.js:148 ~ addResult ~ err:", err);
      toast.error("Result does not declared");
    }
    setShowLoader(false);
  };

  const getBowlingTeamPlayers = async () => {
    try {
      const btp = bowlingTeam.anouncedPlayers
        ? [
            ...JSON.parse(bowlingTeam.anouncedPlayers),
            ...JSON.parse(bowlingTeam.subsitutePlayers),
          ]
        : JSON.parse(bowlingTeam.players);
      btp.forEach(async (p) => {
        try {
          const player = await apiCall({ path: "/player/" + p });
          setBowlTeamPlayers((prev) => [...prev, ...player]);
        } catch (err) {
          console.log(
            "🚀 ~ file: scoring.js:247 ~ getBowlingTeamPlayers ~ err:",
            err
          );
        }
      });
    } catch (err) {
      console.log(
        "🚀 ~ file: scoring.js:244 ~ getBowlingTeamPlayers ~ err:",
        err
      );
    }
  };

  const getLiveResult = async () => {
    setShowLoader(true);
    try {
      const res = await apiCall({
        path: `/live-result?match=${match._id}&team=${bettingTeam._id}`,
      });
      setLiveResults(res);
    } catch (err) {
      console.log("🚀 ~ file: scoring.js:311 ~ getLiveResult ~ err:", err);
    }
    setShowLoader(false);
  };

  const changeEvening = async () => {
    const confirm = window.confirm(
      "Are you sure you want to change evening to " + bowlingTeam.shortName
    );

    if (!confirm) return false;

    setShowLoader(true);
    try {
      await apiCall({
        path: "/match/add",
        data: {
          _id: match._id,
          eveningChanged: match.eveningChanged === 0 ? 1 : 0,
          bowler: 0,
          playerA: 0,
          playerB: 0,
          striker: 0,
          _over: null,
          ov: over,
        },
        method: "post",
      });
      toast.success("Evening chanegs to " + bowlingTeam.shortName);
      window.location.reload();
    } catch (err) {
      console.log("🚀 ~ file: scoring.js:345 ~ changeEvening ~ err:", err);
      toast.error("Cannot change evening");
    }
  };

  const completeMatch = async () => {
    const confirm = window.confirm("Are you sure you want to complete match");

    if (!confirm) return false;

    setShowLoader(true);
    try {
      await apiCall({
        path: "/complete-match/" + match._id,
      });
      toast.success("Match completed successfully");
      navigate(-1);
    } catch (err) {
      console.log("🚀 ~ file: scoring.js:345 ~ changeEvening ~ err:", err);
      toast.error("Cannot complete match");
    }
    setShowLoader(false);
  };

  const getTeamsPlayers = async () => {
    setShowLoader(true);
    try {
      const res = await apiCall({
        path: `/match-players/${match._id}`,
      });
      setTeamAPlayers(res.teamA.players);
      setTeamBPlayers(res.teamB.players);
    } catch (err) {
      console.log("🚀 ~ file: scoring.js:311 ~ getLiveResult ~ err:", err);
    }
    setShowLoader(false);
  };

  const saveLinup = async (teamA, teamB) => {
    setShowLoader(true);
    try {
      await apiCall({
        method: "post",
        path: "/team/add",
        data: {
          _id: teamA,
          anouncedPlayers: JSON.stringify(teamAnouncedPlayers),
          subsitutePlayers: JSON.stringify(teamASubsitutePlayers),
        },
      });

      await apiCall({
        method: "post",
        path: "/team/add",
        data: {
          _id: teamB,
          anouncedPlayers: JSON.stringify(teamBnouncedPlayers),
          subsitutePlayers: JSON.stringify(teamBSubsitutePlayers),
        },
      });

      await apiCall({
        method: "post",
        path: "/match/add",
        data: {
          _id: match._id,
          note: "Lineup Out",
        },
      });

      await apiCall({ path: "/lignup/" + match._id });

      toast.success("Lignup saved successfully");
      getMatch();
    } catch (err) {
      console.log("🚀 ~ saveLinup ~ err:", err);
      setShowLoader(false);
    }
  };

  const getObjectById = (arr, id) => arr.filter((i) => i._id === id)[0];

  const nextOver = (no) => {
    if (no) return over;

    if (!over) return false;

    let _over = parseInt(over.split(".")[0]);
    let _ball = parseInt(over.split(".")[1]);

    if (_ball === 5) {
      _over = _over + 1;
      _ball = 0;
    } else {
      _ball = _ball + 1;
    }

    return `${_over}.${_ball}`;
  };

  const prevOver = (no) => {
    if (no) return over;

    if (!over) return false;

    let _over = parseInt(over.split(".")[0]);
    let _ball = parseInt(over.split(".")[1]);

    if (_ball === 0) {
      if (_over === 1) {
        return false; // No previous over
      }
      _over = _over - 1;
      _ball = 5;
    } else {
      _ball = _ball - 1;
    }

    return `${_over}.${_ball}`;
  };

  useEffect(() => {
    getMatch();
  }, []);

  useEffect(() => {
    match && getTeams();
  }, [match]);

  useEffect(() => {
    if (
      match &&
      bettingTeam &&
      players.length ===
        (!bettingTeam
          ? 0
          : bettingTeam.anouncedPlayers
          ? [
              ...JSON.parse(bettingTeam.anouncedPlayers),
              ...JSON.parse(bettingTeam.subsitutePlayers),
            ].length
          : JSON.parse(bettingTeam.players).length)
    ) {
      match.playerA && setPlayerA(getObjectById(players, match.playerA));
      match.playerB && setPlayerB(getObjectById(players, match.playerB));
      match.bowler && setBowler(getObjectById(bowlTeamPlayers, match.bowler));
    }
  }, [bettingTeam, players]);

  useEffect(() => {
    if (match && teams) {
      if (match.eveningChanged === 0) {
        setBettingTeam(
          match.tossWinner && match.tossWinnerTeamChoice === "Betting"
            ? teams[match.tossWinner]
            : teams[match.tossWinner === "teamA" ? "teamB" : "teamA"]
        );
        setBowlingTeam(
          match.tossWinner && match.tossWinnerTeamChoice === "Bowling"
            ? teams[match.tossWinner]
            : teams[match.tossWinner === "teamA" ? "teamB" : "teamA"]
        );
      } else {
        setBettingTeam(
          match.tossWinner && match.tossWinnerTeamChoice === "Betting"
            ? teams[match.tossWinner === "teamA" ? "teamB" : "teamA"]
            : teams[match.tossWinner]
        );
        setBowlingTeam(
          match.tossWinner && match.tossWinnerTeamChoice === "Bowling"
            ? teams[match.tossWinner === "teamA" ? "teamB" : "teamA"]
            : teams[match.tossWinner]
        );
      }
      //   if (match.playerA === 0 || !match.playerA) {
      //     setPlayerA(null);
      //   }

      //   if (match.playerB === 0 || !match.playerB) {
      //     setPlayerB(null);
      //   }

      //   if (match.bowler === 0 || !match.bowler) {
      //     setBowler(null);
      //   }
    }
  }, [teams, match]);

  useEffect(() => {
    bowlingTeam && getBowlingTeamPlayers();
  }, [bowlingTeam]);

  useEffect(() => {
    bettingTeam && getPlayers();
    bettingTeam && getLiveResult();
  }, [bettingTeam]);

  useEffect(() => {
    liveResults.length > 0 && getLiveScore();
  }, [liveResults, match]);

  useEffect(() => {
    !lineupDone && match && getTeamsPlayers();
  }, [lineupDone, match]);

  useEffect(() => {
    teamAnouncedPlayers.forEach((item) => {
      if (teamASubsitutePlayers.includes(item)) {
        const p = [...teamASubsitutePlayers];
        p.slice(p.indexOf(item), 1);
        setTeamASubsitutePlayers(p);
      }
    });
  }, [teamAnouncedPlayers]);

  useEffect(() => {
    teamBnouncedPlayers.forEach((item) => {
      if (teamBSubsitutePlayers.includes(item)) {
        const p = [...teamBSubsitutePlayers];
        p.slice(p.indexOf(item), 1);
        setTeamBSubsitutePlayers(p);
      }
    });
  }, [teamBnouncedPlayers]);

  return (
    <>
      <Loader show={showLoader} />
      {match && lineupDone && (
        <div className="scoring">
          {match.tossWinner && (
            <>
              {liveScore && bettingTeam && bowlingTeam && (
                <div className="live-score">
                  <div className="scores">
                    {bettingTeam.shortName ===
                    liveScore.teams.teamA.shortName ? (
                      <section>
                        {/* team  */}
                        <div className="team-name">
                          {liveScore.teams.teamA.shortName}
                          {liveScore.teams.teamA.over != "0.0" ||
                          liveScore.teams.teamA.run != 0 ? (
                            <>
                              {" - "}
                              {liveScore.teams.teamA.run} (
                              {liveScore.teams.teamA.over})
                            </>
                          ) : (
                            <> - Yet to bet</>
                          )}
                        </div>
                        {/* players */}
                        {playerA && playerB && (
                          <div className="players">
                            {bettingTeam.shortName ===
                            liveScore.teams.teamA.shortName ? (
                              <>
                                {liveScore.players.playerA && (
                                  <div className="player">
                                    {liveScore.players.playerA.shortName}
                                    {" - "}
                                    {liveScore.players.playerA.run} (
                                    {liveScore.players.playerA.ball})
                                  </div>
                                )}
                                {liveScore.players.playerB && (
                                  <div className="player">
                                    {liveScore.players.playerB.shortName}
                                    {" - "}
                                    {liveScore.players.playerB.run} (
                                    {liveScore.players.playerB.ball})
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                {liveScore.players.bowler && (
                                  <div className="player">
                                    {liveScore.players.bowler.shortName} (
                                    {liveScore.players.bowler.over})
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        )}
                      </section>
                    ) : (
                      <section>
                        {/* team  */}
                        <div className="team-name">
                          {liveScore.teams.teamB.shortName}
                          {liveScore.teams.teamB.over != "0.0" ||
                          liveScore.teams.teamB.run != 0 ? (
                            <>
                              {" - "}
                              {liveScore.teams.teamB.run} (
                              {liveScore.teams.teamB.over})
                            </>
                          ) : (
                            <> - Yet to bet</>
                          )}
                        </div>
                        {/* players */}
                        {liveScore.players.playerA && playerA && playerB && (
                          <div className="players">
                            {bettingTeam.shortName ===
                            liveScore.teams.teamB.shortName ? (
                              <>
                                <div className="player">
                                  {liveScore.players.playerA.shortName}
                                  {" - "}
                                  {liveScore.players.playerA.run} (
                                  {liveScore.players.playerA.ball})
                                </div>
                                <div className="player">
                                  {liveScore.players.playerB.shortName}
                                  {" - "}
                                  {liveScore.players.playerB.run} (
                                  {liveScore.players.playerB.ball})
                                </div>
                              </>
                            ) : (
                              <>
                                {liveScore.players.bowler && (
                                  <div className="player">
                                    {liveScore.players.bowler.shortName} (
                                    {liveScore.players.bowler.over})
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        )}
                      </section>
                    )}

                    {bettingTeam.shortName !==
                    liveScore.teams.teamB.shortName ? (
                      <section>
                        {/* team  */}
                        <div className="team-name">
                          {liveScore.teams.teamB.shortName}
                          {liveScore.teams.teamB.over != "0.0" ||
                          liveScore.teams.teamB.run != 0 ? (
                            <>
                              {" - "}
                              {liveScore.teams.teamB.run} (
                              {liveScore.teams.teamB.over})
                            </>
                          ) : (
                            <> - Yet to bet</>
                          )}
                        </div>
                        {/* players */}
                        <div className="players">
                          {bettingTeam.shortName ===
                          liveScore.teams.teamB.shortName ? (
                            <>
                              <div className="player">
                                {liveScore.players.playerA.shortName}
                                {" - "}
                                {liveScore.players.playerA.run} (
                                {liveScore.players.playerA.ball})
                              </div>
                              <div className="player">
                                {liveScore.players.playerB.shortName}
                                {" - "}
                                {liveScore.players.playerB.run} (
                                {liveScore.players.playerB.ball})
                              </div>
                            </>
                          ) : (
                            <>
                              {liveScore.players.bowler && (
                                <div className="player">
                                  {liveScore.players.bowler.shortName} (
                                  {liveScore.players.bowler.over})
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </section>
                    ) : (
                      <section>
                        {/* team  */}
                        <div className="team-name">
                          {liveScore.teams.teamA.shortName}
                          {liveScore.teams.teamA.over != "0.0" ||
                          liveScore.teams.teamA.run != 0 ? (
                            <>
                              {" - "}
                              {liveScore.teams.teamA.run} (
                              {liveScore.teams.teamA.over})
                            </>
                          ) : (
                            <> - Yet to bet</>
                          )}
                        </div>
                        {/* players */}
                        <div className="players">
                          {bettingTeam.shortName ===
                          liveScore.teams.teamA.shortName ? (
                            <>
                              <div className="player">
                                {liveScore.players.playerA.shortName}
                                {" - "}
                                {liveScore.players.playerA.run} (
                                {liveScore.players.playerA.ball})
                              </div>
                              <div className="player">
                                {liveScore.players.playerB.shortName}
                                {" - "}
                                {liveScore.players.playerB.run} (
                                {liveScore.players.playerB.ball})
                              </div>
                            </>
                          ) : (
                            <>
                              {liveScore.players.bowler && (
                                <div className="player">
                                  {liveScore.players.bowler.shortName} (
                                  {liveScore.players.bowler.over})
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </section>
                    )}
                  </div>
                </div>
              )}

              {match.playerA > 0 &&
                match.playerB > 0 &&
                match.bowler > 0 &&
                playerA &&
                playerB && (
                  <div className="score-container sec" id="score">
                    <h2>Scoring</h2>
                    {liveResults.length > 0 && (
                      <>
                        <h2
                          style={{
                            fontSize: 18,
                            color: "#fff",
                          }}
                        >
                          Live Result
                        </h2>
                        <div className="live-results">
                          {liveResults
                            .sort((a, b) => a._id - b._id)
                            .map((res, index) => (
                              <button
                                disabled={index + 1 !== liveResults.length}
                                className={
                                  "live-result " +
                                  (res._id === resId && "active")
                                }
                                onClick={() => {
                                  if (res._id === resId) {
                                    getMatch();
                                  } else {
                                    setResId(res._id);
                                    setOver(res._over);
                                  }
                                }}
                              >
                                <span className="res">{res.result}</span>
                                <span className="over">{res._over}</span>
                              </button>
                            ))}
                        </div>
                      </>
                    )}
                    <div className="players-selection">
                      <div
                        className={`player ${
                          playerA._id === match.striker && "selected"
                        }`}
                        onClick={() => updateStriker(playerA._id)}
                      >
                        {playerA.shortName}
                      </div>
                      <div
                        className={`player ${
                          playerB._id === match.striker && "selected"
                        }`}
                        onClick={() => updateStriker(playerB._id)}
                      >
                        {playerB.shortName}
                      </div>
                    </div>
                    {playerA._id === match.striker ||
                    playerB._id === match.striker ? (
                      <>
                        <h3>Runs</h3>
                        <section>
                          {runs.map((item) => (
                            <div
                              className="run card"
                              onClick={() => addResult(item.title, item.run)}
                            >
                              {item.title}
                            </div>
                          ))}
                        </section>

                        <h3>Extra</h3>
                        <section>
                          {extra.map((item) => (
                            <div
                              className="run card"
                              onClick={() => addResult(item.title, item.run)}
                            >
                              {item.title}
                            </div>
                          ))}
                        </section>

                        <h3>Wicket</h3>
                        <section>
                          {wicket.map((item) => (
                            <div
                              className="run card"
                              onClick={() => {
                                if (
                                  item.title === "Run Out" ||
                                  item.title === "Run Out + Runs"
                                ) {
                                  setWicketResult(item.title);
                                  setShowRunOutModal(true);
                                } else if (item.title === "Caught Out") {
                                  setWicketResult(item.title);
                                  setCaughtModal(true);
                                } else if (item.title === "Stumped") {
                                  setWicketResult(item.title);
                                  setStumpedModal(true);
                                } else {
                                  addResult(item.title);
                                }
                              }}
                            >
                              {item.title}
                            </div>
                          ))}
                        </section>
                      </>
                    ) : null}

                    <Modal
                      style={modalStyle}
                      isOpen={showRunOutModal}
                      onRequestClose={() => setShowRunOutModal(false)}
                    >
                      <Dropdown
                        options={bowlTeamPlayers.map((item) => ({
                          label: item.shortName,
                          value: item._id,
                        }))}
                        placeholder="Who pass?"
                        className="dropdown"
                        onChange={({ value }) => setWhoPass(value)}
                      />
                      <br />
                      <Dropdown
                        options={bowlTeamPlayers.map((item) => ({
                          label: item.shortName,
                          value: item._id,
                        }))}
                        placeholder="Who throw?"
                        className="dropdown"
                        onChange={({ value }) => setWhoThrow(value)}
                      />
                      <br />
                      <Dropdown
                        options={[
                          {
                            label: playerA.shortName,
                            value: playerA._id,
                          },
                          {
                            label: playerB.shortName,
                            value: playerB._id,
                          },
                        ]}
                        placeholder="Who out?"
                        className="dropdown"
                        onChange={({ value }) => setWhoOut(value)}
                      />
                      <button
                        className="btn create"
                        disabled={!whoThrow || !whoOut}
                        style={{
                          opacity: !whoThrow && !whoOut ? 0.3 : 1,
                        }}
                        onClick={() => addResult(wicketResult, null, whoOut)}
                      >
                        Submit
                      </button>
                    </Modal>

                    <Modal
                      style={modalStyle}
                      isOpen={caughtOutModal}
                      onRequestClose={() => setCaughtModal(false)}
                    >
                      {bowlTeamPlayers.length > 0 && (
                        <Dropdown
                          options={bowlTeamPlayers.map((p) => ({
                            label: p.shortName,
                            value: p._id,
                          }))}
                          placeholder="Who caught?"
                          className="dropdown"
                          onChange={({ value }) => setWhoCaught(value)}
                        />
                      )}
                      <button
                        className="btn create"
                        disabled={!whoCaught}
                        style={{
                          opacity: !whoCaught ? 0.3 : 1,
                        }}
                        onClick={() => addResult(wicketResult)}
                      >
                        Submit
                      </button>
                    </Modal>

                    <Modal
                      style={modalStyle}
                      isOpen={stumpedModal}
                      onRequestClose={() => setStumpedModal(false)}
                    >
                      <Dropdown
                        options={bowlTeamPlayers.map((p) => ({
                          label: p.shortName,
                          value: p._id,
                        }))}
                        placeholder="Whicket keeper"
                        className="dropdown"
                        onChange={({ value }) => setWicketKeeper(value)}
                      />
                      <button
                        className="btn create"
                        disabled={!wicket}
                        style={{
                          opacity: !wicket ? 0.3 : 1,
                        }}
                        onClick={() => addResult(wicketResult)}
                      >
                        Submit
                      </button>
                    </Modal>
                  </div>
                )}

              <div id="players" className="sec">
                <h2>Players</h2>
                <div className="dropdown-container">
                  <span>Player A</span>
                  {players.length > 0 && (
                    <Dropdown
                      options={players.map((player) => ({
                        label: player.shortName,
                        value: player._id,
                      }))}
                      placeholder="Select Player A"
                      className="dropdown"
                      value={
                        playerA && {
                          label: playerA.shortName,
                          value: playerA._id,
                        }
                      }
                      onChange={({ value }) => changePlayer("playerA", value)}
                    />
                  )}
                </div>
                <div className="dropdown-container">
                  <span>Player B</span>
                  <Dropdown
                    options={players.map((player) => ({
                      label: player.shortName,
                      value: player._id,
                    }))}
                    placeholder="Select Player B"
                    className="dropdown"
                    value={
                      playerB && {
                        label: playerB.shortName,
                        value: playerB._id,
                      }
                    }
                    onChange={({ value }) => changePlayer("playerB", value)}
                  />
                </div>
                <div className="dropdown-container">
                  <span>Bowler</span>
                  <Dropdown
                    options={bowlTeamPlayers.map((player) => ({
                      label: player.shortName,
                      value: player._id,
                    }))}
                    placeholder="Select Bowler"
                    className="dropdown"
                    value={
                      bowler && {
                        label: bowler.shortName,
                        value: bowler._id,
                      }
                    }
                    onChange={({ value }) => changePlayer("bowler", value)}
                  />
                </div>
              </div>
            </>
          )}

          <div style={{ width: "100%", flexDirection: "row" }}>
            {bettingTeam && bowlingTeam && match.tossWinner && (
              <button className="btn evening-change" onClick={changeEvening}>
                Change evening to {bowlingTeam.shortName}
              </button>
            )}

            {bettingTeam &&
              bowlingTeam &&
              match.tossWinner &&
              match.eveningChanged == 1 && (
                <button className="btn evening-change" onClick={completeMatch}>
                  Complete Match
                </button>
              )}
          </div>
        </div>
      )}

      {teams && match && over <= 5 && (!match.tossWinner || lineupDone) && (
        <div className="scoring">
          <div id="toss" className="sec">
            <h2>Toss</h2>
            <div className="dropdown-container">
              <span>Who won toss?</span>
              <Dropdown
                options={[
                  {
                    label: `Team A (${teams.teamA.shortName})`,
                    value: "teamA",
                  },
                  {
                    label: `Team B (${teams.teamB.shortName})`,
                    value: "teamB",
                  },
                ]}
                placeholder="Select Toss Winner"
                className="dropdown"
                onChange={({ value }) => setTossWinner(value)}
                value={
                  tossWinner && {
                    label:
                      tossWinner === "teamA"
                        ? `Team A (${teams.teamA.shortName})`
                        : `Team B (${teams.teamB.shortName})`,
                    value: tossWinner,
                  }
                }
              />
            </div>
            <div className="dropdown-container">
              <span>Toss Winner Team Choise</span>
              <Dropdown
                options={["Betting", "Bowling"]}
                placeholder="Select Option"
                className="dropdown"
                onChange={({ value }) => setTossWinnerTeamChoice(value)}
                value={tossWinnerTeamChoice}
              />
            </div>
            <button
              className="btn"
              disabled={!tossWinner || !tossWinnerTeamChoice}
              style={{
                opacity: !tossWinner || !tossWinnerTeamChoice ? 0.5 : 1,
              }}
              onClick={tossSubmit}
            >
              Save
            </button>
          </div>
        </div>
      )}

      {/* lineup */}
      {match && match.tossWinner && !lineupDone && teams && (
        <div className="lineup">
          <section>
            <span className="team-name">{teams.teamA.shortName}</span>
            <div className="tabs">
              <div
                className={lineupTab === 0 ? "active-tab" : "tab"}
                onClick={() => setLineupTab(0)}
              >
                Announced
              </div>
              <div
                className={lineupTab === 1 ? "active-tab" : "tab"}
                onClick={() => setLineupTab(1)}
              >
                Subsitute
              </div>
            </div>
            <br />
            <div className="players">
              {teamAPlayers.map((item) => {
                const ps =
                  lineupTab === 0 ? teamAnouncedPlayers : teamASubsitutePlayers;

                const setPs =
                  lineupTab === 0
                    ? setTeamAAnouncedPlayers
                    : setTeamASubsitutePlayers;

                return (
                  <>
                    {(lineupTab === 0 ||
                      !teamAnouncedPlayers.includes(item._id)) && (
                      <div
                        className={`player ${
                          ps.includes(item._id) && "player-active"
                        } ${lineupTab == 1 && ps.includes(item._id) && "blue"}`}
                        onClick={() => {
                          if (ps.includes(item._id)) {
                            const n = [...ps];
                            n.splice(ps.indexOf(item._id), 1);
                            setPs(n);
                          } else {
                            const n = [...ps];
                            n.push(item._id);
                            setPs(n);
                          }
                        }}
                      >
                        <img
                          src={baseURL + item.image}
                          className="player-img"
                        />{" "}
                        {item.shortName}
                        {ps.includes(item._id) && (
                          <Icon
                            name="done"
                            className={lineupTab === 1 && "blue"}
                          />
                        )}
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </section>

          <section>
            <span className="team-name">{teams.teamB.shortName}</span>
            <div className="tabs">
              <div
                className={lineupTab === 0 ? "active-tab" : "tab"}
                onClick={() => setLineupTab(0)}
              >
                Announced
              </div>
              <div
                className={lineupTab === 1 ? "active-tab" : "tab"}
                onClick={() => setLineupTab(1)}
              >
                Subsitute
              </div>
            </div>
            <br />
            <div className="players">
              {teamBPlayers.map((item) => {
                const ps =
                  lineupTab === 0 ? teamBnouncedPlayers : teamBSubsitutePlayers;

                const setPs =
                  lineupTab === 0
                    ? setTeamBAnouncedPlayers
                    : setTeamBSubsitutePlayers;

                return (
                  <>
                    {(lineupTab === 0 ||
                      !teamBnouncedPlayers.includes(item._id)) && (
                      <div
                        className={`player ${
                          ps.includes(item._id) && "player-active"
                        } ${lineupTab == 1 && ps.includes(item._id) && "blue"}`}
                        onClick={() => {
                          if (ps.includes(item._id)) {
                            const n = [...ps];
                            n.splice(ps.indexOf(item._id), 1);
                            setPs(n);
                          } else {
                            const n = [...ps];
                            n.push(item._id);
                            setPs(n);
                          }
                        }}
                      >
                        <img
                          src={baseURL + item.image}
                          className="player-img"
                        />{" "}
                        {item.shortName}
                        {ps.includes(item._id) && (
                          <Icon
                            name="done"
                            className={lineupTab === 1 && "blue"}
                          />
                        )}
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </section>
          <button
            onClick={() => saveLinup(teams.teamA._id, teams.teamB._id)}
            disabled={
              teamBnouncedPlayers.length === 0 ||
              teamAnouncedPlayers.length === 0
            }
          >
            SAVE Lineup
          </button>
        </div>
      )}
    </>
  );
};

export default Scoring;
