import { Vortex } from "react-loader-spinner";

const Loader = ({ show }) => {
    return (
        <>
            {show && (
                <div className="loader">
                    <Vortex
                        visible={show}
                        height="150"
                        width="150"
                        ariaLabel="vortex-loading"
                        wrapperClass="vortex-wrapper"
                        colors={[
                            "red",
                            "green",
                            "blue",
                            "yellow",
                            "orange",
                            "purple",
                        ]}
                    />
                </div>
            )}
        </>
    );
};

export default Loader;
