import Dropdown from "react-dropdown";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";

import Input from "../components/input";
import Header from "../components/header";
import Loader from "../components/loader";

import apiCall from "../functions/apiCall";
import toast from "../functions/toast";

const MatchesAdd = () => {
    const [tournaments, setTournaments] = useState([]);
    const [teams, setTeams] = useState([]);
    const [stadiums, setStadiums] = useState([]);
    const [showLoader, setShowLoader] = useState(false);

    const { id } = useParams();
    const navigate = useNavigate();

    const timestampRegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

    const initialValues = {
        tournament: null,
        stadium: null,
        status: null,
        teamA: null,
        teamB: null,
        startTime: null,
        endTime: null,
    };

    const validationSchema = yup.object({
        tournament: yup.string().required("Tournament cannot be empty"),
        stadium: yup.string().required("Stadium cannot be empty"),
        status: yup.string().required("Status cannot be empty"),
        teamA: yup.string().required("Team A cannot be empty"),
        teamB: yup.string().required("Team B cannot be empty"),
        startTime: yup.date().required("Start Time cannot be empty"),
        endTime: yup.date().required("End Time cannot be empty"),
    });

    const { values, errors, handleSubmit, touched, setFieldValue } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: () => addMatch(),
    });

    const getTournaments = async () => {
        try {
            let res = await apiCall({ path: "/tournaments" });
            res = res.map((t) => ({ label: t.name, value: t._id }));
            setTournaments(res);
        } catch (err) {
            console.log(
                "🚀 ~ file: matchesAdd.js:27 ~ getTournaments ~ err:",
                err
            );
        }
    };

    const getTeams = async () => {
        try {
            let res = await apiCall({ path: "/teams" });
            res = res.map((t) => ({ label: t.shortName, value: t._id }));
            setTeams(res);
        } catch (err) {
            console.log(
                "🚀 ~ file: matchesAdd.js:27 ~ getTournaments ~ err:",
                err
            );
        }
    };

    const getStadiums = async () => {
        try {
            let res = await apiCall({ path: "/grounds" });
            res = res.map((t) => ({ label: t.name, value: t._id }));
            setStadiums(res);
        } catch (err) {
            console.log(
                "🚀 ~ file: matchesAdd.js:27 ~ getTournaments ~ err:",
                err
            );
        }
    };

    const addMatch = async () => {
        setShowLoader(true);
        try {
            if (id) values._id = id;
            await apiCall({
                path: "/match/add",
                data: values,
                method: "post",
            });
            toast.success(`Match ${id ? "updated" : "created"} successfully`);
            navigate("/matches");
        } catch (err) {
            console.log("🚀 ~ file: matchesAdd.js:96 ~ addMatch ~ err:", err);
        }
        setShowLoader(false);
    };

    const getMatch = async () => {
        setShowLoader(true);
        try {
            let [res] = await apiCall({ path: "/match/" + id });
            const keys = Object.keys(res);
            keys.forEach((key) => {
                setFieldValue(
                    key,
                    timestampRegExp.test(res[key])
                        ? new Date(res[key])
                        : res[key]
                );
            });
            console.log(moment(res.startDate).format("DD-MM-YYYY hh:mm a"));
        } catch (err) {
            console.log(
                "🚀 ~ file: tournamentsAdd.js:52 ~ getCategories ~ err:",
                err
            );
        }
        setShowLoader(false);
    };

    const getObjectFromVal = (obj, val) =>
        obj.filter((item) => item.value === val)[0];

    useEffect(() => {
        getTournaments();
        getTeams();
        getStadiums();
        id && getMatch();
    }, []);

    return (
        <div className="categories tournaments">
            <Header title="Matches > Add" />
            <Loader show={showLoader} />
            <div className="machesinput">
                <br />
                <Dropdown
                    options={tournaments}
                    placeholder="Tournament"
                    className="dropdown"
                    onChange={({ value }) => setFieldValue("tournament", value)}
                    value={
                        values.tournament &&
                        getObjectFromVal(tournaments, values.tournament)
                    }
                />
                {touched.tournament && errors.tournament && (
                    <div className="err">{errors.tournament}</div>
                )}
                <br />
                <Dropdown
                    options={teams}
                    placeholder="Team A"
                    className="dropdown"
                    onChange={({ value }) => setFieldValue("teamA", value)}
                    value={
                        values.teamA && getObjectFromVal(teams, values.teamA)
                    }
                />
                {touched.teamA && errors.teamA && (
                    <div className="err">{errors.teamA}</div>
                )}
                <br />

                <Dropdown
                    options={teams}
                    placeholder="Team B"
                    className="dropdown"
                    onChange={({ value }) => setFieldValue("teamB", value)}
                    value={
                        values.teamB && getObjectFromVal(teams, values.teamB)
                    }
                />
                {touched.teamB && errors.teamB && (
                    <div className="err">{errors.teamB}</div>
                )}
                <div className="matche" style={{ marginTop: 30 }}>
                    <Input
                        label="Start Date Time"
                        type="datetime-local"
                        time
                        onChange={(date) => setFieldValue("startTime", date)}
                        error={touched.startTime && errors.startTime}
                        value={values.startTime}
                    />
                </div>

                <div className="matche">
                    <Input
                        label="End Date Time"
                        type="datetime-local"
                        time
                        onChange={(date) => setFieldValue("endTime", date)}
                        error={touched.endTime && errors.endTime}
                        value={values.endTime}
                    />
                </div>
                <br />

                <Dropdown
                    options={stadiums}
                    placeholder="Stadium"
                    className="dropdown"
                    onChange={({ value }) => setFieldValue("stadium", value)}
                    value={
                        values.stadium &&
                        getObjectFromVal(stadiums, values.stadium)
                    }
                />
                {touched.stadium && errors.stadium && (
                    <div className="err">{errors.stadium}</div>
                )}
                <br />

                <Dropdown
                    options={["Upcoming", "Live", "Completed"]}
                    placeholder="Status"
                    className="dropdown"
                    onChange={({ value }) => setFieldValue("status", value)}
                    value={values.status}
                />
                {touched.status && errors.status && (
                    <div className="err">{errors.status}</div>
                )}
            </div>
            <button className="btn cat-btn create" onClick={handleSubmit}>
                {id ? "Update" : "Create"}
            </button>
        </div>
    );
};

export default MatchesAdd;
