import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Header from "../components/header";
import Icon from "../components/icon";
import Loader from "../components/loader";
import Input from "../components/input";

import apiCall, { baseURL } from "../functions/apiCall";
import toast from "../functions/toast";

const MatchesAddPlayer = () => {
    const [players, setPlayers] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const [teamName, setTeamName] = useState(null);

    const navigate = useNavigate();
    const _id = useParams().teamID;

    const getPlayers = async () => {
        try {
            const res = await apiCall({ path: "/players" });
            setPlayers(res);
        } catch (err) {
            console.log(
                "🚀 ~ file: matchesAddPlayer.js:16 ~ getPlayers ~ err:",
                err
            );
        }
    };

    const handleSave = async () => {
        try {
            let _players = players.filter((i) => i.active === true);

            if (_players.length === 0) {
                return toast.error("Please select atleast 1 player");
            } else {
                _players.forEach((p, i) => (_players[i] = p._id));
                _players = JSON.stringify(_players);
                await apiCall({
                    method: "post",
                    path: "/team/add",
                    data: { players: _players, _id },
                });
                toast.success("Players added successfully");
                return navigate("/teams");
            }
        } catch (err) {
            console.log(
                "🚀 ~ file: matchesAddPlayer.js:27 ~ handleSave ~ err:",
                err
            );
            toast.error(err);
        }
    };

    const getTeamPlayers = async () => {
        try {
            let [res] = await apiCall({ path: "/team/" + _id });
            setTeamName(res.fullName);
            const teamPlayers = JSON.parse(res.players);
            const updatedPlayers = players.map((player) => ({
                ...player,
                active: teamPlayers.includes(player._id),
            }));
            setPlayers(updatedPlayers);
            setShowLoader(false);
        } catch (err) {
            console.log("Error:", err);
        }
    };

    const searchPlayer = (e) => {
        const searchText = e.target.value.toLowerCase();

        if (!searchText) {
            return setPlayers(players.slice().sort((a, b) => a._id - b._id));
        }

        const matchingPlayers = players.filter((player) =>
            player.fullName.toLowerCase().includes(searchText)
        );
        const remainingPlayers = players.filter(
            (player) => !player.fullName.toLowerCase().includes(searchText)
        );
        const updatedPlayers = [...matchingPlayers, ...remainingPlayers];
        setPlayers(updatedPlayers);
    };

    useEffect(() => {
        getPlayers();
    }, []);

    useEffect(() => {
        players.length !== 0 && getTeamPlayers();
    }, [players.length]);

    return (
        <div className="categories tournaments">
            <Header title="Matches > Add-Player" />
            <Loader show={showLoader} />
            <div className="addplayer">
                <section>
                    <div className="playerteam">
                        <h4>{teamName}</h4>
                        <h4>
                            {
                                players.filter((item) => item.active === true)
                                    .length
                            }{" "}
                            / {players.length}
                        </h4>
                    </div>
                    <div className="card">
                        <Input label="Search player" onChange={searchPlayer} />
                        <br />
                        {players.map((item, index) => (
                            <div
                                className={
                                    "playername " + (item.active && "active")
                                }
                                onClick={() => {
                                    const updatedplayers = players.map(
                                        (player, i) =>
                                            i === index
                                                ? {
                                                      ...player,
                                                      active: !player.active,
                                                  }
                                                : player
                                    );
                                    setPlayers(updatedplayers);
                                }}
                            >
                                <img
                                    src={baseURL + item.image}
                                    className="player-img"
                                />
                                {item.active && <Icon name="done" />}{" "}
                                {item.fullName} ({item.roll})
                            </div>
                        ))}
                    </div>
                </section>
            </div>
            <button className="btn cat-btn create" onClick={handleSave}>
                Save
            </button>
        </div>
    );
};

export default MatchesAddPlayer;
