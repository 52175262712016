import Dropdown from "react-dropdown";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Header from "../components/header";
import Input from "../components/input";
import Loader from "../components/loader";

import apiCall, { baseURL } from "../functions/apiCall";
import toast from "../functions/toast";
import axios from "axios";

const TournamentsAdd = () => {
    const [categories, setCategories] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const [countries, setCountries] = useState([]);

    const navigate = useNavigate();
    const { id } = useParams();

    const timestampRegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

    const points = {
        runs: null,
        four: null,
        six: null,
        50: null,
        100: null,
        wicket: null,
        "2Wickets": null,
        "3Wickets": null,
        stumping: null,
        runOutDirectThrow: null,
        runOutThrowPassed: null,
        maidenOver: null,
        caught: null,
        bowled: null,
        lbw: null,
    };

    const initialValues = {
        category: null,
        name: null,
        image: null,
        nationality: null,
        startDate: null,
        endDate: null,
        ...points,
    };

    const validationSchema = yup.object({
        category: yup.string().required("Category cannot be empty"),
        name: yup.string().required("Tournament Name cannot be empty"),
        image: yup.mixed().required("Image cannot be empty"),
        nationality: yup.string().required("Nationality Name cannot be empty"),
        startDate: yup.date().required("Start Date cannot be empty"),
        endDate: yup.date().required("End Date cannot be empty"),
        // points
        runs: yup
            .string()
            .required("Points cannot be empty")
            .matches(/^(?!0\d+)\d+(\.\d+)?$/, "Enter valid points"),
        four: yup
            .string()
            .required("Points cannot be empty")
            .matches(/^(?!0\d+)\d+(\.\d+)?$/, "Enter valid points"),
        six: yup
            .string()
            .required("Points cannot be empty")
            .matches(/^(?!0\d+)\d+(\.\d+)?$/, "Enter valid points"),
        50: yup
            .string()
            .required("Points cannot be empty")
            .matches(/^(?!0\d+)\d+(\.\d+)?$/, "Enter valid points"),
        100: yup
            .string()
            .required("Points cannot be empty")
            .matches(/^(?!0\d+)\d+(\.\d+)?$/, "Enter valid points"),
        wicket: yup
            .string()
            .required("Points cannot be empty")
            .matches(/^(?!0\d+)\d+(\.\d+)?$/, "Enter valid points"),
        "2Wickets": yup
            .string()
            .required("Points cannot be empty")
            .matches(/^(?!0\d+)\d+(\.\d+)?$/, "Enter valid points"),
        "3Wickets": yup
            .string()
            .required("Points cannot be empty")
            .matches(/^(?!0\d+)\d+(\.\d+)?$/, "Enter valid points"),
        stumping: yup
            .string()
            .required("Points cannot be empty")
            .matches(/^(?!0\d+)\d+(\.\d+)?$/, "Enter valid points"),
        runOutDirectThrow: yup
            .string()
            .required("Points cannot be empty")
            .matches(/^(?!0\d+)\d+(\.\d+)?$/, "Enter valid points"),
        runOutThrowPassed: yup
            .string()
            .required("Points cannot be empty")
            .matches(/^(?!0\d+)\d+(\.\d+)?$/, "Enter valid points"),
        maidenOver: yup
            .string()
            .required("Points cannot be empty")
            .matches(/^(?!0\d+)\d+(\.\d+)?$/, "Enter valid points"),
    });

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: () => addTournament(),
    });

    const getCategories = async () => {
        try {
            const res = await apiCall({ path: "/categories" });
            const cats = [];
            res.forEach((item) => {
                cats.push({ value: item._id, label: item.name });
            });
            setCategories(cats);
        } catch (err) {
            console.log(
                "🚀 ~ file: tournamentsAdd.js:52 ~ getCategories ~ err:",
                err
            );
        }
        setShowLoader(false);
    };

    const addTournament = async () => {
        setShowLoader(true);
        try {
            const formData = new FormData();
            formData.append("file", values.image);

            if (typeof values.image === "object") {
                let image = await apiCall({
                    path: "/file/upload",
                    data: formData,
                    method: "post",
                });
                values.image = image[0];
            }

            const _points = { _id: values.pointsID };

            Object.keys(points).forEach((item) => {
                _points[item] = values[item];
                delete values[item];
            });

            await apiCall({
                path: "/tournament/add",
                data: { ...values, points: _points },
                method: "post",
            });
            toast.success(
                `Tournament ${id ? "updated" : "created"} successfully`
            );
            navigate("/tournaments");
        } catch (err) {
            console.log(
                "🚀 ~ file: tournamentsAdd.js:69 ~ addTournament ~ err:",
                err
            );
            toast.error(err);
        }
        setShowLoader(false);
    };

    const getTournament = async () => {
        setShowLoader(true);
        try {
            let res = await apiCall({ path: "/tournament/" + id });
            res = res[0];
            const keys = Object.keys(res);
            keys.forEach((key) => {
                setFieldValue(
                    key,
                    timestampRegExp.test(res[key])
                        ? new Date(res[key])
                        : res[key]
                );
            });

            const [data] = await apiCall({ path: "/points/" + id });
            delete data.createdAt;
            setFieldValue("pointsID", data._id);
            delete data._id;
            const keys2 = Object.keys(data);
            keys2.forEach((item) => {
                setFieldValue(item, data[item]);
            });
        } catch (err) {
            console.log(
                "🚀 ~ file: tournamentsAdd.js:52 ~ getCategories ~ err:",
                err
            );
        }
        setShowLoader(false);
    };

    const getCountries = async () => {
        setShowLoader(true);
        try {
            const { data } = await axios.get(
                "https://restcountries.com/v3.1/all"
            );
            setCountries(["All", ...data.map((i) => i.name.common).sort()]);
        } catch (err) {
            console.log("🚀 ~ getCountries ~ err:", err);
        }
        setShowLoader(false);
    };

    const getObjectFromVal = (obj, val) =>
        obj.filter((item) => item.value === val)[0];

    useEffect(() => {
        getCategories();
        getCountries();
        id && getTournament();
    }, []);

    return (
        <div className="categories tournaments">
            <Header title="Tournaments > Add" />
            <Loader show={showLoader} />
            <div className="tournamentinput">
                <Dropdown
                    options={categories}
                    placeholder="Category"
                    className="dropdown"
                    onChange={(e) => {
                        setFieldValue("category", e.value);
                    }}
                    value={
                        values.category &&
                        getObjectFromVal(categories, values.category)
                    }
                />
                {touched.category && errors.category && (
                    <div className="err">{errors.category}</div>
                )}
                <br />
                <div className="wrapper">
                    <Input
                        label="Tournament Name"
                        onChange={handleChange("name")}
                        onBlur={handleBlur("name")}
                        value={values.name}
                        error={touched.name && errors.name}
                    />
                </div>
                <div className="wrapper ">
                    <Input
                        label="Tournament Image"
                        type="file"
                        onChange={(file) => setFieldValue("image", file)}
                        error={touched.image && errors.image}
                        value={
                            values.image && typeof values.image === "object"
                                ? URL.createObjectURL(values.image)
                                : values.image
                                ? baseURL + values.image
                                : null
                        }
                    />
                </div>
                <Dropdown
                    options={countries}
                    placeholder="Nationality"
                    className="dropdown"
                    onChange={(e) => {
                        setFieldValue("nationality", e.value);
                    }}
                    value={values.nationality}
                />
                {touched.nationality && errors.nationality && (
                    <div className="err">{errors.nationality}</div>
                )}
                <br />
                <div className="wrapper">
                    <Input
                        label="Start Date"
                        type="date"
                        onChange={(date) => setFieldValue("startDate", date)}
                        value={values.startDate}
                        error={touched.startDate && errors.startDate}
                    />
                </div>
                <div className="wrapper">
                    <Input
                        label="End Date"
                        type="date"
                        onChange={(date) => setFieldValue("endDate", date)}
                        value={values.endDate}
                        error={touched.endDate && errors.endDate}
                    />
                </div>
                <br />
                {Object.keys(points).map((item, index) => (
                    <div className="wrapper">
                        <Input
                            label={item
                                .replace(/([A-Z])/g, " $1")
                                .toLowerCase()}
                            onChange={handleChange(item)}
                            value={values[item]}
                            error={touched[item] && errors[item]}
                        />
                    </div>
                ))}
                <button className="btn cat-btn create" onClick={handleSubmit}>
                    {id ? "Update" : "Create"}
                </button>
            </div>
        </div>
    );
};

export default TournamentsAdd;
