import Modal from "react-modal";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import Header from "../components/header";
import Icon from "../components/icon";
import Input from "../components/input";
import Loader from "../components/loader";

import apiCall from "../functions/apiCall";
import toast from "../functions/toast";

const Categories = () => {
    const [showModal, setShowModal] = useState(false);
    const [categories, setCategories] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const [_id, setId] = useState(null);

    const modalStyle = {
        content: {
            width: 300,
            height: 200,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 12,
            background: "#000",
        },
    };

    const {
        values,
        handleChange,
        handleSubmit,
        handleBlur,
        errors,
        setFieldValue,
        setFieldError,
    } = useFormik({
        initialValues: { name: null },
        validationSchema: yup.object({
            name: yup.string().required("Category name cannot be empty"),
        }),
        onSubmit: () => addCategory(),
    });

    const getCategories = async () => {
        setShowLoader(true);
        try {
            const res = await apiCall({ path: "/categories" });
            setCategories(res);
            setShowLoader(false);
        } catch (err) {
            console.log(
                "🚀 ~ file: categories.js:27 ~ getCategories ~ err:",
                err
            );
            setShowLoader(false);
        }
    };

    const addCategory = async () => {
        setShowLoader(true);
        try {
            await apiCall({
                path: "/category/add",
                data: { ...values, _id },
                method: "post",
            });
            getCategories();
            setShowModal(false);
            setShowLoader(false);
            toast.success(`Category ${_id ? "updated" : "added"} successfully`);
        } catch (err) {
            console.log(
                "🚀 ~ file: categories.js:27 ~ getCategories ~ err:",
                err
            );
            setShowLoader(false);
            toast.error(err);
        }
    };

    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        !showModal && setId(null);
        !showModal && setFieldValue("name", null);
        setFieldError("name", null);
    }, [showModal]);

    return (
        <div className="categories">
            <Loader show={showLoader} />
            <Header title="Categories" />
            <Modal
                isOpen={showModal}
                style={modalStyle}
                onRequestClose={() => setShowModal(false)}
            >
                {showModal && (
                    <div className="add-category">
                        <Input
                            label="Category Name"
                            onChange={handleChange("name")}
                            onBlur={handleBlur("name")}
                            value={values.name}
                            error={errors.name}
                        />
                        <button className="btn cat-btn" onClick={handleSubmit}>
                            {_id ? "Update" : "Add"} Category
                        </button>
                    </div>
                )}
            </Modal>
            <button className="btn add" onClick={() => setShowModal(true)}>
                <Icon name="add" /> Add Category
            </button>
            <div className="cards">
                {categories.map((item, i) => (
                    <div
                        className="card category"
                        key={i}
                        onClick={() => {
                            setShowModal(true);
                            setId(item._id);
                            setFieldValue("name", item.name);
                        }}
                    >
                        {item.name}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Categories;
