import Dropdown from "react-dropdown";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";

import images from "../constants/images";

import Header from "../components/header";
import Icon from "../components/icon";
import Loader from "../components/loader";

import apiCall, { baseURL } from "../functions/apiCall";
import toast from "../functions/toast";

const Dashboard = () => {
    const [mathces, setMatches] = useState([]);
    const [teams, setTeams] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const [grounds, setGrounds] = useState([]);

    const tournamentOptions = ["All", "Tata Ipl 2023", "T20 World Cup 2023"],
        teamOptions = ["All", "Live", "Upcoming", "Completed "];

    const getTeam = async (a, b) => {
        try {
            const [teamA] = await apiCall({ path: "/team/" + a });
            const [teamB] = await apiCall({ path: "/team/" + b });
            setTeams((prev) => [...prev, ...[{ teamA, teamB }]]);
        } catch (err) {
            console.log("🚀 ~ file: matches.js:23 ~ getTeam ~ err:", err);
            toast.error(err || "Get Team Error");
        }
    };

    const getStadium = async (id) => {
        try {
            const stadium = await apiCall({ path: "/ground/" + id });
            setGrounds((prev) => [...prev, ...stadium]);
        } catch (err) {
            console.log("🚀 ~ file: matches.js:23 ~ getTeam ~ err:", err);
            toast.error(err || "Get Team Error");
        }
    };

    const getMatches = async (status) => {
        status = !status ? "live" : status;
        setShowLoader(true);
        setTeams([]);
        setGrounds([]);
        try {
            const path =
                status === "All" ? "/matches" : "/matches?status=" + status;
            const res = await apiCall({ path });
            setMatches(res);
            res.forEach((team) => {
                getTeam(team.teamA, team.teamB);
                getStadium(team.stadium);
            });
        } catch (err) {
            console.log("🚀 ~ file: matches.js:20 ~ getMatches ~ err:", err);
        }
        setShowLoader(false);
    };

    useEffect(() => {
        getMatches();
    }, []);

    useEffect(() => {
        if (teams.length === mathces.length) {
            setShowLoader(false);
        } else {
            setShowLoader(true);
        }
    }, [mathces, teams]);

    return (
        <div className="categories tournaments">
            <Header title="Matches" />
            <Loader show={showLoader} />
            <div className="wrapper">
                <div className="dropdown-wrapper">
                    <Dropdown
                        options={tournamentOptions}
                        placeholder="Select an Tournament"
                        className="dropdown teamdropdown"
                    />
                    <Dropdown
                        options={teamOptions}
                        placeholder="Select an Status"
                        className="dropdown teamdropdown"
                        onChange={({ value }) => getMatches(value)}
                        value="Live"
                    />
                </div>
                <Link to="/matches/add" className="btn add">
                    <Icon name="add" /> Add Match
                </Link>
            </div>
            {teams.length === mathces.length &&
                teams.length === grounds.length && (
                    <div className="cards">
                        {teams.map((team, teamI) => (
                            <Link
                                className="matchse card"
                                to={
                                    mathces[teamI].status !== "Live"
                                        ? "/matches/" + mathces[teamI]._id
                                        : "/dashboard/score/" +
                                          mathces[teamI]._id
                                }
                            >
                                <section>
                                    <div className="team">
                                        <img
                                            src={baseURL + team.teamA.image}
                                            className="team-img"
                                        />
                                        <div className="teamName">
                                            {team.teamA.shortName}
                                        </div>
                                    </div>
                                    <img src={images.vs} id="vs" />
                                    <div className="team">
                                        <img
                                            src={baseURL + team.teamB.image}
                                            className="team-img"
                                        />
                                        <div className="teamName">
                                            {team.teamB.shortName}
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <div>
                                        <span>
                                            Start Time:{" "}
                                            {moment(
                                                mathces[teamI].startTime
                                            ).format("DD-MM-YY hh:mm a")}
                                        </span>
                                        <span>
                                            Status: {mathces[teamI].status}
                                        </span>
                                        <span>
                                            Stadium: {grounds[teamI].name}
                                        </span>
                                    </div>
                                </section>
                            </Link>
                        ))}
                    </div>
                )}
        </div>
    );
};

export default Dashboard;
