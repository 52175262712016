import uid from "harsh-uid";
import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";

import Icon from "./icon";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";

const Input = ({
    label,
    type = "text",
    onChange,
    inputClass,
    labelClass,
    autoFocus,
    value,
    time,
    onBlur,
    error,
    ...props
}) => {
    const [focused, setFocused] = useState(false);
    const [val, setVal] = useState(value || null);
    const [file, setFile] = useState(value || null);

    const inputRef = useRef(null);
    const id = uid();

    useEffect(() => {
        if (focused) {
            inputRef.current.focus();
        }
    }, [focused]);

    useEffect(() => {
        if (value && type === "file") setFile(value);
        if (value) setVal(value);
    }, [value]);

    return (
        <>
            {type === "file" ? (
                <div className="fp">
                    <label htmlFor={id}>
                        <Icon name="add_photo_alternate" />
                        {label}
                    </label>
                    <input
                        type="file"
                        style={{ display: "none" }}
                        id={id}
                        onChange={(e) => {
                            const file = e.currentTarget.files[0] || null;
                            !onChange &&
                                file &&
                                setFile(URL.createObjectURL(file));
                            onChange && onChange(file);
                        }}
                        accept="image/*"
                    />
                    {file && <img src={file} />}
                    {error && <div className="err">{error}</div>}
                </div>
            ) : (
                <div className="input-container">
                    <label
                        className={labelClass + " " + (focused && "active")}
                        style={{ top: focused || val ? -10 : 10 }}
                        onClick={() => setFocused(true)}
                    >
                        {label}
                    </label>
                    <input
                        type={type}
                        onChange={(e) => {
                            onChange &&
                                onChange(
                                    type === "date" || type === "datetime-local"
                                        ? new Date(e.currentTarget.value)
                                        : e
                                );
                            setVal(e.currentTarget.value);
                        }}
                        className={inputClass}
                        onFocus={() => setFocused(true)}
                        onBlur={(e) => {
                            setFocused(false);
                            onBlur && onBlur(e);
                        }}
                        autoFocus={autoFocus}
                        value={
                            type === "datetime-local"
                                ? moment(value).format("YYYY-MM-DDTHH:mm")
                                : type === "date"
                                ? moment(value).format("YYYY-MM-DD")
                                : value
                        }
                        ref={inputRef}
                        {...props}
                    />
                    {error && <div className="err">{error}</div>}
                </div>
            )}
        </>
    );
};

export default Input;
