import { useEffect, useState } from "react";
import Dropdown from "react-dropdown";
import { Link } from "react-router-dom";
import moment from "moment/moment";

import Header from "../components/header";
import Loader from "../components/loader";
import Icon from "../components/icon";

import apiCall, { baseURL } from "../functions/apiCall";

const Tournaments = () => {
    const [tournaments, setTournaments] = useState([]);
    const [categories, setCategories] = useState([]);
    const [cat, setCat] = useState(0);
    const [showLoader, setShowLoader] = useState(true);

    const options = ["All", "International", "Domestic", "Spacial"];

    const getTournaments = async () => {
        setShowLoader(true);
        try {
            const path =
                cat != 0 ? "/tournaments?category=" + cat : "/tournaments";
            console.log(
                "🚀 ~ file: tournaments.js:22 ~ getTournaments ~ path:",
                path
            );

            const res = await apiCall({ path });
            setTournaments(res);
        } catch (err) {
            console.log(
                "🚀 ~ file: tournaments.js:17 ~ getTournaments ~ err:",
                err
            );
        }
        setShowLoader(false);
    };

    const getCategories = async () => {
        try {
            const res = await apiCall({ path: "/categories" });
            const cats = [{ label: "All", value: 0 }];
            res.forEach((item) => {
                cats.push({ value: item._id, label: item.name });
            });
            setCategories(cats);
        } catch (err) {
            console.log(
                "🚀 ~ file: tournamentsAdd.js:52 ~ getCategories ~ err:",
                err
            );
        }
        setShowLoader(false);
    };

    const getObjectFromVal = (obj, val) =>
        obj.filter((item) => item.value === val)[0];

    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        getTournaments();
    }, [cat]);

    return (
        <div className="categories tournaments">
            <Header title="Tournaments" />
            <Loader show={showLoader} />
            <div className="wrapper">
                <Dropdown
                    options={categories}
                    placeholder="Select an option"
                    className="dropdown"
                    onChange={(e) => setCat(e.value)}
                    value={getObjectFromVal(categories, cat)}
                />
                <Link to="/tournaments/add" className="btn add">
                    <Icon name="add" /> Add Tournament
                </Link>
            </div>
            <div className="cards">
                {tournaments.map((item) => (
                    <Link
                        className="card tournament"
                        to={"/tournaments/" + item._id}
                    >
                        <img src={baseURL + item.image} />
                        <div className="info">
                            <span>{item.name}</span>
                            <span>
                                {moment(item.startDate).format("MM-DD-YYYY")}
                            </span>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default Tournaments;
