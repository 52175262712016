import Dropdown from "react-dropdown";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import Input from "../components/input";
import Header from "../components/header";

import apiCall, { baseURL } from "../functions/apiCall";
import toast from "../functions/toast";
import Loader from "../components/loader";
import axios from "axios";

const PlayersAdd = () => {
    const [showLoader, setShowLoader] = useState(false);
    const [teams, setTeams] = useState([]);
    const [teamSearch, setTeamSearch] = useState(null);
    const [countries, setCountries] = useState([]);

    const navigate = useNavigate();

    const { id } = useParams();

    const timestampRegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

    const rollOptions = ["Batsmen", "Bowler", "All Rounders", "Wicket Keeper"];

    const batsmenOptions = ["Right Hand Bat", "Left Hand Bat"];

    const bowlerOptions = [
        "Right-arm fast",
        "Right-arm fast-medium",
        "Right-arm medium-fast",
        "Right-arm medium",
        "Right-arm medium-slow",
        "Right-arm slow-medium",
        "Right-arm slow",
        "Left-arm fast",
        "Left-arm fast-medium",
        "Left-arm medium-fast",
        "Left-arm medium",
        "Left-arm medium-slow",
        "Left-arm slow-medium",
        "Left-arm slow",
        "Off break",
        "Leg break",
        "Leg break googly",
        "Slow left-arm orthodox",
        "Slow left-arm wrist spin",
        "Left-arm googly",
    ];

    const initialValues = {
        fullName: null,
        shortName: null,
        image: null,
        roll: null,
        bowlingType: null,
        bettingType: null,
        birthDate: null,
        nationality: null,
        city: null,
        teamName: null,
        price: null,
    };

    const validationSchema = yup.object({
        fullName: yup.string().required("Full Name cannot be empty"),
        shortName: yup.string().required("Short Name cannot be empty"),
        image: yup.string().required("Image cannot be empty"),
        roll: yup.string().required("Roll cannot be empty"),
        bowlingType: yup.string().required("Bowling Type cannot be empty"),
        bettingType: yup.string().required("Betting Type cannot be empty"),
        birthDate: yup.date().required("Date cannot be empty"),
        nationality: yup.string().required("Nationality cannot be empty"),
        city: yup.string().required("City cannot be empty"),
        teamName: yup.string().required("Team Name cannot be empty"),
        price: yup
            .string()
            .required("Price Name cannot be empty")
            .matches(/^\d+(\.\d+)?$/, "Enter valid price"),
    });

    const {
        values,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        setFieldValue,
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: () => addPlayer(),
    });

    const addPlayer = async () => {
        setShowLoader(true);
        try {
            const formData = new FormData();
            formData.append("file", values.image);

            if (typeof values.image === "object") {
                let image = await apiCall({
                    path: "/file/upload",
                    data: formData,
                    method: "post",
                });
                values.image = image[0];
            }

            await apiCall({
                path: "/player/add",
                data: values,
                method: "post",
            });
            toast.success(`Player ${id ? "updated" : "created"} successfully`);
            navigate("/players");
        } catch (err) {
            console.log(
                "🚀 ~ file: tournamentsAdd.js:69 ~ addPlayer ~ err:",
                err
            );
            toast.error(err);
        }
        setShowLoader(false);
    };

    const getPlayers = async () => {
        setShowLoader(true);
        try {
            let res = await apiCall({ path: "/player/" + id });
            res = res[0];
            const keys = Object.keys(res);
            keys.forEach((key) => {
                setFieldValue(
                    key,
                    timestampRegExp.test(res[key])
                        ? new Date(res[key])
                        : res[key]
                );
            });
        } catch (err) {
            console.log(
                "🚀 ~ file: tournamentsAdd.js:52 ~ getCategories ~ err:",
                err
            );
        }
        setShowLoader(false);
    };

    const getTeams = async () => {
        setShowLoader(true);
        try {
            const res = await apiCall({ path: "/teams" });
            setTeams(res);
        } catch (err) {
            console.log("🚀 ~ file: playersAdd.js:143 ~ getTeams ~ err:", err);
        }
        setShowLoader(false);
    };

    const getCountries = async () => {
        setShowLoader(true);
        try {
            const { data } = await axios.get(
                "https://restcountries.com/v3.1/all"
            );
            setCountries(data.map((i) => i.name.common).sort());
        } catch (err) {
            console.log("🚀 ~ getCountries ~ err:", err);
        }
        setShowLoader(false);
    };

    useEffect(() => {
        id && getPlayers();
        getTeams();
        getCountries();
    }, []);

    return (
        <div className="categories tournaments">
            <Header title="Players > Add" />
            <Loader show={showLoader} />
            <div className="machesinput">
                <div className="matche">
                    <Input
                        label="Player Image"
                        type="file"
                        onChange={(file) => setFieldValue("image", file)}
                        error={touched.image && errors.image}
                        value={
                            values.image && typeof values.image === "object"
                                ? URL.createObjectURL(values.image)
                                : values.image
                                ? baseURL + values.image
                                : null
                        }
                    />
                </div>
                <div className="matche">
                    <Input
                        label="Player Full Name"
                        onChange={handleChange("fullName")}
                        onBlur={handleBlur("fullName")}
                        error={touched.fullName && errors.fullName}
                        value={values.fullName}
                    />
                </div>
                <div className="matche">
                    <Input
                        label="Player Short Name"
                        onChange={handleChange("shortName")}
                        onBlur={handleBlur("shortName")}
                        error={touched.shortName && errors.shortName}
                        value={values.shortName}
                    />
                </div>
                <div className="matche">
                    <Input
                        label="Player City"
                        onChange={handleChange("city")}
                        onBlur={handleBlur("city")}
                        error={touched.city && errors.city}
                        value={values.city}
                    />
                </div>
                <br />
                <Dropdown
                    options={countries}
                    placeholder="Nationality"
                    className="dropdown"
                    onChange={({ value }) =>
                        setFieldValue("nationality", value)
                    }
                    value={values.nationality}
                />
                {touched.nationality && errors.nationality && (
                    <div className="err">{errors.nationality}</div>
                )}
                <br />
                <Dropdown
                    options={rollOptions}
                    placeholder="Roll"
                    className="dropdown"
                    onChange={({ value }) => setFieldValue("roll", value)}
                    value={values.roll}
                />
                {touched.roll && errors.roll && (
                    <div className="err">{errors.roll}</div>
                )}
                <br />

                <Dropdown
                    options={batsmenOptions}
                    placeholder="Betting Type"
                    className="dropdown"
                    onChange={({ value }) =>
                        setFieldValue("bettingType", value)
                    }
                    value={values.bettingType}
                />
                {touched.bettingType && errors.bettingType && (
                    <div className="err">{errors.bettingType}</div>
                )}
                <br />

                <Dropdown
                    options={bowlerOptions}
                    placeholder="Bowling Type"
                    className="dropdown"
                    onChange={({ value }) =>
                        setFieldValue("bowlingType", value)
                    }
                    value={values.bowlingType}
                />
                {touched.bowlingType && errors.bowlingType && (
                    <div className="err">{errors.bowlingType}</div>
                )}
                <br />
                <div className="matche">
                    <Input
                        placeholder="Search Team"
                        label="Search Team"
                        onChange={(e) => {
                            const searchText = e.target.value.toLowerCase();

                            setTeamSearch(searchText);

                            if (!searchText) {
                                return setTeams(
                                    teams.slice().sort((a, b) => a._id - b._id)
                                );
                            }

                            const matchingPlayers = teams.filter((player) =>
                                player.shortName
                                    .toLowerCase()
                                    .includes(searchText)
                            );
                            const remainingPlayers = teams.filter(
                                (player) =>
                                    !player.shortName
                                        .toLowerCase()
                                        .includes(searchText)
                            );
                            const updatedPlayers = [
                                ...matchingPlayers,
                                ...remainingPlayers,
                            ];
                            setTeams(updatedPlayers);
                        }}
                    />
                    <br />
                    {!teamSearch && values.teamName ? (
                        <div
                            style={{
                                padding: 10,
                                backgroundColor: "#db5860",
                                fontSize: 14,
                                margin: 2,
                                cursor: "pointer",
                            }}
                        >
                            {values.teamName}
                        </div>
                    ) : (
                        <div style={{ maxHeight: 250, overflow: "auto" }}>
                            {teams.map((t) => (
                                <div
                                    style={{
                                        padding: 10,
                                        backgroundColor:
                                            t.shortName === values.teamName
                                                ? "#db5860"
                                                : "#000",
                                        fontSize: 14,
                                        margin: 2,
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setFieldValue("teamName", t.shortName);
                                    }}
                                >
                                    {t.shortName}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                {touched.teamName && errors.teamName && (
                    <div className="err">{errors.teamName}</div>
                )}
                <br />
                <br />

                <Input
                    label="Player Birthdate"
                    type="date"
                    onChange={(date) => setFieldValue("birthDate", date)}
                    error={touched.birthDate && errors.birthDate}
                    value={values.birthDate}
                />
                <br />

                <Input
                    label="Player Price"
                    onChange={handleChange("price")}
                    error={touched.price && errors.price}
                    value={values.price}
                />
            </div>
            <button className="btn cat-btn create" onClick={handleSubmit}>
                {id ? "Update" : "Create"}
            </button>
        </div>
    );
};

export default PlayersAdd;
