import Dropdown from "react-dropdown";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";

import images from "../constants/images";

import Header from "../components/header";
import Icon from "../components/icon";
import Loader from "../components/loader";

import apiCall, { baseURL } from "../functions/apiCall";
import toast from "../functions/toast";

const Players = () => {
  const [mathces, setMatches] = useState([]);
  const [teams, setTeams] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [grounds, setGrounds] = useState([]);
  const [page, setPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);

  const tournamentOptions = ["All", "Tata Ipl 2023", "T20 World Cup 2023"],
    teamOptions = ["All", "Live", "Upcoming", "Completed "];

  const getTeam = async (a, b, i) => {
    try {
      const [teamA] = await apiCall({ path: "/team/" + a });
      const [teamB] = await apiCall({ path: "/team/" + b });
      return { teamA, teamB };
    } catch (err) {
      console.log("🚀 ~ file: matches.js:23 ~ getTeam ~ err:", err);
      toast.error(err || "Get Team Error");
    }
  };

  const getStadium = async (id) => {
    try {
      const [stadium] = await apiCall({ path: "/ground/" + id });
      return stadium;
    } catch (err) {
      console.log("🚀 ~ file: matches.js:23 ~ getTeam ~ err:", err);
      toast.error(err || "Get Team Error");
    }
  };

  const getMatches = async (status, p) => {
    setShowLoader(true);
    setTeams([]);
    setGrounds([]);
    try {
      const path =
        !status || status === "All"
          ? `/matches?page=${page}&numOfItem=${10}`
          : `/matches?status=${status}&page=${page}&numOfItem=${10}`;
      const res = await apiCall({ path });

      const prom = res.map(async (team, i) => {
        const teams = await getTeam(team.teamA, team.teamB, i);
        const ground = await getStadium(team.stadium);
        res[i].teams = teams;
        res[i].ground = ground;
        return res[i];
      });

      await Promise.all(prom);

      p === 1 ? setMatches(res) : setMatches([...mathces, ...res]);
      p === 1 && setPage(1);
      setIsLastPage(res.length < 10);
    } catch (err) {
      console.log("🚀 ~ file: matches.js:20 ~ getMatches ~ err:", err);
    }
    setShowLoader(false);
  };

  useEffect(() => {
    getMatches();
  }, [page]);

  // useEffect(() => {
  //   if (teams.length === mathces.length) {
  //     setShowLoader(false);
  //   } else {
  //     setShowLoader(true);
  //   }
  // }, [mathces, teams]);

  // useEffect(() => {
  //   console.log("matches: ", mathces);
  // }, [mathces]);

  return (
    <div className="categories tournaments">
      <Header title="Matches" />
      <Loader show={showLoader} />
      <div className="wrapper">
        <div className="dropdown-wrapper">
          <Dropdown
            options={tournamentOptions}
            placeholder="Select an Tournament"
            className="dropdown teamdropdown"
          />
          <Dropdown
            options={teamOptions}
            placeholder="Select an Status"
            className="dropdown teamdropdown"
            onChange={({ value }) => getMatches(value, 1)}
          />
        </div>
        <Link to="/matches/add" className="btn add">
          <Icon name="add" /> Add Match
        </Link>
      </div>
      <div className="cards">
        {mathces.map((team, teamI) => (
          <Link className="matchse card" to={"/matches/" + team._id}>
            <section>
              <div className="team">
                <img
                  src={baseURL + team.teams.teamA.image}
                  className="team-img"
                />
                <div className="teamName">{team.teams.teamA.shortName}</div>
              </div>
              <img src={images.vs} id="vs" />
              <div className="team">
                <img
                  src={baseURL + team.teams.teamB.image}
                  className="team-img"
                />
                <div className="teamName">{team.teams.teamB.shortName}</div>
              </div>
            </section>
            <section>
              <div>
                <span>
                  Start Time:{" "}
                  {moment(mathces[teamI].startTime).format("DD-MM-YY hh:mm a")}
                </span>
                <span>Status: {mathces[teamI].status}</span>
                <span>Stadium: {team.ground.name}</span>
              </div>
            </section>
          </Link>
        ))}
      </div>
      {teams.length === mathces.length &&
        teams.length === grounds.length &&
        !isLastPage && (
          <button
            className="btn"
            style={{ width: 200, margin: "0 auto" }}
            onClick={() => setPage(page + 1)}
          >
            Load More
          </button>
        )}
    </div>
  );
};

export default Players;
