import { useState, useEffect } from "react";
import Dropdown from "react-dropdown";
import { Link } from "react-router-dom";

import Header from "../components/header";
import Icon from "../components/icon";
import Loader from "../components/loader";

import apiCall, { baseURL } from "../functions/apiCall";

const Teams = () => {
    const [categories, setCategories] = useState([]);
    const [cat, setCat] = useState(0);
    const [showLoader, setShowLoader] = useState(true);
    const [teams, setTeams] = useState([]);
    const [page, setPage] = useState(1);
    const [isLastPage, setIsLastPage] = useState(false);

    const getTeams = async () => {
        setShowLoader(true);
        try {
            const path = !cat
                ? `/teams?page=${page}&numOfItem=${10}`
                : `/teams?category=${cat}&page=${page}&numOfItem=${10}`;
            const res = await apiCall({ path });
            console.log("🚀 ~ getTeams ~ res:", res);
            setIsLastPage(res.length < 10);
            setTeams([...teams, ...res]);
        } catch (err) {
            console.log("🚀 ~ file: tournaments.js:17 ~ getTeams ~ err:", err);
        }
        setShowLoader(false);
    };

    const getCategories = async () => {
        try {
            const res = await apiCall({ path: "/categories" });
            const cats = [{ label: "All", value: 0 }];
            res.forEach((item) => {
                cats.push({ value: item._id, label: item.name });
            });
            setCategories(cats);
        } catch (err) {
            console.log(
                "🚀 ~ file: tournamentsAdd.js:52 ~ getCategories ~ err:",
                err
            );
        }
        setShowLoader(false);
    };

    const getObjectFromVal = (obj, val) =>
        obj.filter((item) => item.value === val)[0];

    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        getTeams();
    }, [cat, page]);

    return (
        <div className="categories tournaments">
            <Header title="Teams" />
            <Loader show={showLoader} />
            <div className="wrapper">
                <div className="dropdown-wrapper">
                    <Dropdown
                        options={categories}
                        placeholder="Select an Category"
                        className="dropdown"
                        onChange={({ value }) => setCat(value)}
                    />
                </div>
                <Link to="/teams/add" className="btn add">
                    <Icon name="add" /> Add Team
                </Link>
            </div>

            <div className="cards">
                {teams.map((item) => (
                    <Link
                        className="card tournament teamCard"
                        to={"/teams/" + item._id}
                    >
                        <img src={baseURL + item.image} />
                        <div className="info">
                            <span>{item.fullName}</span>
                        </div>
                    </Link>
                ))}
            </div>
            {teams.length > 0 && !isLastPage && (
                <button
                    className="btn"
                    style={{ width: 200, margin: "0 auto", marginTop: 20 }}
                    onClick={() => setPage(page + 1)}
                >
                    Load More
                </button>
            )}
        </div>
    );
};

export default Teams;
