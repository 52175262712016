import Dropdown from "react-dropdown";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Input from "../components/input";
import Header from "../components/header";
import Loader from "../components/loader";

import toast from "../functions/toast";
import apiCall from "../functions/apiCall";
import axios from "axios";

const GroundAdd = () => {
    const [showLoader, setShowLoader] = useState(false);
    const [countries, setCountries] = useState([]);

    const navigate = useNavigate();
    const { id } = useParams();

    const tournamentOptions = ["India", "China", "United States"];

    const initialValues = {
        name: null,
        location: null,
        country: null,
    };

    const validationSchema = yup.object({
        name: yup.string().required("Ground Name cannot be empty"),
        location: yup.string().required("Ground Location cannot be empty"),
        country: yup.string().required("Ground Country cannot be empty"),
    });

    const {
        values,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        setFieldValue,
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: () => addGround(),
    });

    const addGround = async () => {
        setShowLoader(true);
        try {
            await apiCall({
                data: { ...values, ...(id && { _id: id }) },
                method: "post",
                path: "/ground/add",
            });
            toast.success(`Ground ${id ? "updated" : "created"} successfully`);
            navigate("/ground");
        } catch (err) {
            console.log("🚀 ~ file: groundAdd.js:41 ~ addGround ~ err:", err);
            toast(err);
        }
        setShowLoader(false);
    };

    const getGround = async () => {
        setShowLoader(true);
        try {
            let [res] = await apiCall({ path: "/ground/" + id });
            const keys = Object.keys(res);
            keys.forEach((key) => {
                setFieldValue(key, res[key]);
            });
        } catch (err) {
            console.log("🚀 ~ file: groundAdd.js:68 ~ getGround ~ err:", err);
            toast(err);
        }
        setShowLoader(false);
    };

    const getCountries = async () => {
        setShowLoader(true);
        try {
            const { data } = await axios.get(
                "https://restcountries.com/v3.1/all"
            );
            setCountries(data.map((i) => i.name.common).sort());
        } catch (err) {
            console.log("🚀 ~ getCountries ~ err:", err);
        }
        setShowLoader(false);
    };

    useEffect(() => {
        id && getGround();
        getCountries();
    }, []);

    return (
        <div className="categories tournaments">
            <Header title="Ground > Add" />
            <Loader show={showLoader} />
            <div className="machesinput">
                <div className="matche">
                    <Input
                        label="Ground Name"
                        onChange={handleChange("name")}
                        onBlur={handleBlur("name")}
                        error={touched.name && errors.name}
                        value={values.name}
                    />
                </div>
                <div className="matche">
                    <Input
                        label="Ground Location"
                        onChange={handleChange("location")}
                        onBlur={handleBlur("location")}
                        error={touched.location && errors.location}
                        value={values.location}
                    />
                </div>
                <br />
                <Dropdown
                    options={countries}
                    placeholder="Ground Country"
                    className="dropdown"
                    onChange={({ value }) => setFieldValue("country", value)}
                    value={values.country}
                />
                {touched.country && errors.country && (
                    <div className="err">{errors.country}</div>
                )}
            </div>
            <button className="btn cat-btn create" onClick={handleSubmit}>
                {id ? "Update" : "Create"}
            </button>
        </div>
    );
};

export default GroundAdd;
