import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import Icon from "./icon";

const Sidebar = () => {
    const [path, setPath] = useState(null);

    const location = useLocation();

    useEffect(() => {
        const path = location.pathname.split("/")[1];
        setPath(path);
    }, [location]);

    return (
        <div className="sidebar">
            <Link
                to="/dashboard"
                className={"list " + (path === "dashboard" && "active")}
            >
                <Icon name="dashboard" />
                <span>Dashboard</span>
            </Link>
            <Link
                to="/categories"
                className={"list " + (path === "categories" && "active")}
            >
                <Icon name="sports_cricket" />
                <span>Categories</span>
            </Link>
            <Link
                to="/tournaments"
                className={"list " + (path === "tournaments" && "active")}
            >
                <Icon name="emoji_events" />
                <span>Tournaments</span>
            </Link>
            <Link
                to="/teams"
                className={"list " + (path === "teams" && "active")}
            >
                <Icon name="groups" />
                <span>Teams</span>
            </Link>
            <Link
                to="/matches"
                className={"list " + (path === "matches" && "active")}
            >
                <Icon name="scoreboard" />
                <span>Matches</span>
            </Link>
            <Link
                to="/players"
                className={"list " + (path === "players" && "active")}
            >
                <Icon name="directions_run" />
                <span>Players</span>
            </Link>
            <Link
                to="/ground"
                className={"list " + (path === "ground" && "active")}
            >
                <Icon name="grass" />
                <span>Ground</span>
            </Link>
        </div>
    );
};

export default Sidebar;
