import moment from "moment";
import { useEffect, useState } from "react";
import Dropdown from "react-dropdown";
import { Link } from "react-router-dom";

import Header from "../components/header";
import Icon from "../components/icon";
import Loader from "../components/loader";
import Input from "../components/input";

import apiCall, { baseURL } from "../functions/apiCall";

const Players = () => {
    const [showLoader, setShowLoader] = useState(true);
    const [role, setRole] = useState(null);
    const [players, setPlayers] = useState([]);
    const [page, setPage] = useState(1);
    const [isLastPage, setIsLastPage] = useState(false);

    const rollOptions = ["All", "Batsmen", "Bowler", "All-Rounder"];

    const getPlayers = async () => {
        setShowLoader(true);
        try {
            const path =
                !role || role === "All"
                    ? `/players?page=${page}&numOfItem=${10}`
                    : `/players?roll=${role}&page=${page}&numOfItem=${10}`;

            const res = await apiCall({ path });
            setPlayers([...players, ...res]);
            setIsLastPage(res.length < 10);
        } catch (err) {
            console.log("🚀 ~ file: players.js:19 ~ getPlayers ~ err:", err);
        }
        setShowLoader(false);
    };

    const searchPlayer = async ({ target: { value } }) => {
        try {
            const path =
                !role || role === "All"
                    ? `/players?page=${page}&numOfItem=${10}&q=${value}`
                    : `/players?roll=${role}&page=${page}&numOfItem=${10}&q=${value}`;

            const res = await apiCall({ path });
            console.log("🚀 ~ searchPlayer ~ res:", res);
            setPlayers(res);
            setIsLastPage(res.length < 10);
        } catch (err) {
            console.log("🚀 ~ file: players.js:19 ~ getPlayers ~ err:", err);
        }
    };

    useEffect(() => {
        getPlayers();
    }, [role, page]);

    return (
        <div className="categories tournaments">
            <Header title="Players" />
            <Loader show={showLoader} />
            <div className="wrapper">
                <div className="dropdown-wrapper">
                    <Dropdown
                        options={rollOptions}
                        placeholder="Roll"
                        className="dropdown teamdropdown"
                        onChange={({ value }) => setRole(value)}
                    />
                </div>
                <Link to="/players/add" className="btn add">
                    <Icon name="add" /> Add Player
                </Link>
            </div>
            <br />
            <Input label="Search Player" onChange={searchPlayer} />
            <div className="cards ">
                {players.map((player) => (
                    <Link
                        className="card tournament teamCard playerCard"
                        to={"/players/" + player._id}
                    >
                        <img src={baseURL + player.image} />
                        <div className="info">
                            <span>Name: {player.shortName}</span>
                            {/* <span>Team: Chennai Super Kings</span> */}
                            <span>Roll: {player.roll}</span>
                            <span>
                                DOB:{" "}
                                {moment(player.birthDate).format("DD-MM-YYYY")}
                            </span>
                        </div>
                    </Link>
                ))}
                {players.length > 0 && !isLastPage && (
                    <button
                        className="btn"
                        style={{ width: 200, margin: "0 auto", marginTop: 20 }}
                        onClick={() => setPage(page + 1)}
                    >
                        Load More
                    </button>
                )}
            </div>
        </div>
    );
};

export default Players;
