import axios from "axios";

const apiCall = (e) =>
    new Promise(async (resolve, reject) => {
        try {
            const { path, method, data } = e;

            const { REACT_APP_BASE_URL: apiUrl } = process.env;

            const config = {
                method: method || "get",
                url: apiUrl + path,
                data,
            };
            let res = await axios(config);
            res = res.data;
            if (res.status === "SUCCESS") {
                resolve(res.data);
            } else {
                reject(res.message || "Something went wrong");
            }
        } catch (err) {
            reject(err);
        }
    });

export default apiCall;
export const baseURL = process.env.REACT_APP_BASE_URL;
