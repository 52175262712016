import Dropdown from "react-dropdown";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Header from "../components/header";
import Input from "../components/input";
import Loader from "../components/loader";

import apiCall, { baseURL } from "../functions/apiCall";
import toast from "../functions/toast";
import axios from "axios";

const TeamsAdd = () => {
    const [categories, setCategories] = useState([]);
    const [stadiums, setStadiums] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const [_id, set_id] = useState(null);
    const [totalPlayers, setTotalPlayers] = useState(0);
    const [players, setPlayers] = useState([]);
    const [playersArr, setPlayersArr] = useState([]);
    const [countries, setCountries] = useState([]);

    const navigate = useNavigate();
    const { id } = useParams();

    const initialValues = {
        category: null,
        fullName: null,
        shortName: null,
        image: null,
        nationality: null,
        stadium: null,
    };

    const validationSchema = yup.object({
        category: yup.string().required("Category cannot be empty"),
        fullName: yup.string().required("Team Full Name cannot be empty"),
        shortName: yup.string().required("Team Short Name cannot be empty"),
        image: yup.mixed().required("Image cannot be empty"),
        nationality: yup.string().required("Nationality cannot be empty"),
        stadium: yup.string().required("Stadium cannot be empty"),
    });

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: () => addTeam(),
    });

    const getStadiums = async () => {
        try {
            const res = await apiCall({ path: "/grounds" });
            const cats = [];
            res.forEach((item) => {
                cats.push({ value: item._id, label: item.name });
            });
            setStadiums(cats);
        } catch (err) {
            console.log(
                "🚀 ~ file: tournamentsAdd.js:52 ~ getStadiums ~ err:",
                err
            );
        }
        setShowLoader(false);
    };

    const getCategories = async () => {
        try {
            const res = await apiCall({ path: "/categories" });
            const cats = [];
            res.forEach((item) => {
                cats.push({ value: item._id, label: item.name });
            });
            setCategories(cats);
        } catch (err) {
            console.log(
                "🚀 ~ file: tournamentsAdd.js:52 ~ getCategories ~ err:",
                err
            );
        }
        setShowLoader(false);
    };

    const addTeam = async () => {
        setShowLoader(true);
        try {
            const formData = new FormData();
            formData.append("file", values.image);

            if (typeof values.image === "object") {
                let image = await apiCall({
                    path: "/file/upload",
                    data: formData,
                    method: "post",
                });
                values.image = image[0];
            }

            const res = await apiCall({
                path: "/team/add",
                data: values,
                method: "post",
            });
            !_id && set_id(res._id);
            toast.success(`Team info saved successfully`);
            navigate(
                "/teams/add/players/" +
                    (res && res._id && res._id != 0 ? res._id : _id)
            );
        } catch (err) {
            console.log(
                "🚀 ~ file: tournamentsAdd.js:69 ~ addTeam ~ err:",
                err
            );
            toast.error(err);
        }
        setShowLoader(false);
    };

    const getTeam = async () => {
        setShowLoader(true);
        setPlayers([]);
        try {
            const [res] = await apiCall({ path: "/team/" + id });
            set_id(res._id);
            setTotalPlayers(JSON.parse(res.players).length);
            const keys = Object.keys(res);
            keys.forEach((key) => {
                setFieldValue(key, res[key]);
            });
            const players = JSON.parse(res.players);
            setPlayersArr(players);
            players.forEach((player) => getPlayer(player));
        } catch (err) {
            console.log(
                "🚀 ~ file: tournamentsAdd.js:52 ~ getCategories ~ err:",
                err
            );
        }
        setShowLoader(false);
    };

    const getPlayer = async (id) => {
        try {
            const res = await apiCall({ path: "/player/" + id });
            setPlayers((prev) => [...prev, ...res]);
        } catch (err) {
            console.log("🚀 ~ file: teamsAdd.js:153 ~ getPlayer ~ err:", err);
        }
    };

    const removePlayer = async (id) => {
        const isConfirmed = window.confirm(
            "Are you sure you want to remove player?"
        );

        if (!isConfirmed) {
            return false;
        }

        setShowLoader(true);
        try {
            const updatedPlayers = playersArr.filter((i) => i !== id);
            await apiCall({
                path: "/team/add",
                method: "post",
                data: { players: JSON.stringify(updatedPlayers), _id },
            });
            getTeam();
            toast.success("Player removed successfully");
        } catch (err) {
            console.log(
                "🚀 ~ file: teamsAdd.js:165 ~ removePlayer ~ err:",
                err
            );
            toast.success(err);
        }
        setShowLoader(false);
    };

    const getObjectFromVal = (obj, val) =>
        obj.filter((item) => item.value === val)[0];

    const getCountries = async () => {
        setShowLoader(true);
        try {
            const { data } = await axios.get(
                "https://restcountries.com/v3.1/all"
            );
            setCountries(data.map((i) => i.name.common).sort());
        } catch (err) {
            console.log("🚀 ~ getCountries ~ err:", err);
        }
        setShowLoader(false);
    };

    useEffect(() => {
        getCountries();
        getCategories();
        getStadiums();
        id && getTeam();
    }, []);

    return (
        <div className="categories tournaments">
            <Loader show={showLoader} />
            <Header title="Teams > Add" />
            <div className="team-cont">
                <div className="wrapper">
                    <div className="add-category">
                        <Dropdown
                            options={categories}
                            placeholder="Category"
                            className="dropdown"
                            onChange={(e) => {
                                setFieldValue("category", e.value);
                            }}
                            value={
                                values.category &&
                                getObjectFromVal(categories, values.category)
                            }
                        />
                        {touched.category && errors.category && (
                            <div className="err">{errors.category}</div>
                        )}
                        <div className="teaminput">
                            <div className="wrapper">
                                <Input
                                    label="Team Full Name"
                                    onChange={handleChange("fullName")}
                                    onBlur={handleBlur("fullName")}
                                    error={touched.fullName && errors.fullName}
                                    value={values.fullName}
                                />
                            </div>
                            <div className="wrapper">
                                <Input
                                    label="Team Short Name"
                                    onChange={handleChange("shortName")}
                                    onBlur={handleBlur("shortName")}
                                    error={
                                        touched.shortName && errors.shortName
                                    }
                                    value={values.shortName}
                                />
                            </div>
                            <br />
                            <Dropdown
                                options={countries}
                                placeholder="Nationality"
                                className="dropdown teamdropdown nationality"
                                onChange={({ value }) =>
                                    setFieldValue("nationality", value)
                                }
                                value={values.nationality}
                            />
                            {touched.nationality && errors.nationality && (
                                <div className="err">{errors.nationality}</div>
                            )}
                            <br />
                            <Dropdown
                                options={stadiums}
                                placeholder="Stadium"
                                className="dropdown"
                                onChange={(e) => {
                                    setFieldValue("stadium", e.value);
                                }}
                                value={
                                    values.stadium &&
                                    getObjectFromVal(stadiums, values.stadium)
                                }
                            />
                            {touched.stadium && errors.stadium && (
                                <div className="err">{errors.stadium}</div>
                            )}
                            <br />
                            <Input
                                label="Team Image"
                                type="file"
                                onChange={(file) =>
                                    setFieldValue("image", file)
                                }
                                value={
                                    values.image &&
                                    typeof values.image === "object"
                                        ? URL.createObjectURL(values.image)
                                        : values.image
                                        ? baseURL + values.image
                                        : null
                                }
                            />
                            {touched.image && errors.image && (
                                <div className="err">{errors.image}</div>
                            )}
                        </div>
                        <button className="btn cat-btn" onClick={handleSubmit}>
                            Next
                        </button>
                    </div>
                </div>
                <div className="team-players-total">
                    <h2>Total Selected Players: {totalPlayers}</h2>
                    <br />
                    <div className="selected-players">
                        {players.map((player, i) => (
                            <div className="player-sec">
                                <div className="player-name">
                                    {i + 1}. {player.fullName}
                                </div>
                                <span
                                    className="clear"
                                    onClick={() => removePlayer(player._id)}
                                >
                                    X
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamsAdd;
