import { toast as toast2 } from "react-toastify";

const toast = {
    success: (msg) => {
        toast2.success(msg, { autoClose: 1500 });
    },
    error: (msg) => {
        toast2.error(msg, { autoClose: 1500 });
    },
};

export default toast;
